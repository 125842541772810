import React, { useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import styles from "./style.module.scss";
import classNames from "classnames";
import Unchecked from "../../../assets/icons/unchecked.svg";
import Checked from "../../../assets/icons/checked.svg";

const transportData = [
  {
    id: "is_rent_car",
    title: "Rent car",
    imgUrl: "/images/transfer.jpg",
    description: "How do you create compelling presentations that wow your…",
  },
  {
    id: "is_airport_transfer",
    title: "Airport transfers",
    description: "How do you create compelling presentations that wow your…",
    imgUrl: "/images/transport.png",
  },
];

const TransportSection = ({ setValue, control, watch }) => {
  const [selected, setSelected] = useState([]);

  const handleToggle = (watch, id) => {
    const isSelected = selected.includes(id);
    const updatedSelected = isSelected
      ? selected.filter((item) => item !== id)
      : [...selected, id];

    setSelected(updatedSelected);
    setValue(id, !watch);
  };

  return (
    <Box className={styles.container}>
      <Typography variant="h6" className={styles.title}>
        Transport
      </Typography>

      <Box className={styles.grid}>
        {transportData.map((item) => {
          return (
            <Card className={styles.card}>
              <Box className={styles.imageWrapper}>
                <CardMedia
                  component="img"
                  image={item.imgUrl}
                  alt={item.title}
                  className={styles.image}
                />
              </Box>
              <CardContent className={styles.content}>
                <Box className={styles.desc}>
                  <Typography variant="h6" className={styles.content_title}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" className={styles.description}>
                    {item.description}
                  </Typography>
                </Box>
                <Button
                  variant={watch(item?.id) ? "contained" : "outlined"}
                  color="success"
                  onClick={() => handleToggle(watch(item?.id), item.id)}
                  className={classNames(styles.addButton, {
                    [styles.active]: watch(item?.id),
                  })}
                >
                  <img
                    src={watch(item?.id) ? Checked : Unchecked}
                    alt="check"
                  />
                  Add
                </Button>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default TransportSection;
