import styles from "./style.module.scss";
import logo from "../../../assets/icons/logo.png";
import { Box, Container, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";

export default function Header({
  externalOrderId,
  agent_number,
  agent_fullname,
  control,
}) {
  const image = useWatch({
    control,
    name: "image",
  });
  return (
    <header className={styles.header}>
      <Container className={styles.container}>
        <img src={logo} alt="logo" className={styles.logo} />
        <Box
          display="flex"
          alignItems={"center"}
          columnGap={"16px"}
          className={styles.content}
        >
          <Box className={styles.avatar}>
            <img
              src={image || "/ava.png"}
              alt="avatar"
              width={"48px"}
              height={"48px"}
              className={styles.ava}
            />
            <span />
          </Box>
          <Box className={styles.desc}>
            <Typography
              fontSize="16px"
              fontWeight={600}
              display="flex"
              lineHeight={"24px"}
              color={"#101828"}
              alignItems="center"
              className={styles.name}
            >
              {agent_fullname || "Elyor Mirzaboyev"}
            </Typography>
            <Typography display="flex" alignItems="center">
              <a href={`tel:+18007639229`}> +1 (800) 763 9229</a>
            </Typography>
          </Box>
        </Box>
      </Container>
    </header>
  );
}
