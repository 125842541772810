import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Avatar, Box, Checkbox, Slider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breakfast from "../../../assets/icons/breakfast.svg";
import { IOSSwitch } from "../../../themes/overrides/IosSwitch";
import styles from "./style.module.scss";
import { useWatch } from "react-hook-form";

const protectionFeatures = [
  {
    id: 1,
    icon: <img alt="icon" src={Breakfast} />,
    text: "Flexible tickets for 24 hours",
  },
  { id: 2, icon: <img alt="icon" src={Breakfast} />, text: "Protected flight" },
  { id: 3, icon: <img alt="icon" src={Breakfast} />, text: "Trip change" },
  {
    id: 4,
    icon: <img alt="icon" src={Breakfast} />,
    text: "Additional services",
  },
];

const marks = [
  { value: 0, label: "$0" },
  { value: 20, label: "$20" },
  { value: 40, label: "$40" },
  { value: 60, label: "$60" },
  { value: 80, label: "$80" },
  { value: 100, label: "$100" },
];

const InsuranceSection = ({ setValue, confirmTip, setConfirmTip, control }) => {
  const [travelProtection, setTravelProtection] = useState(true);
  const [amount, setAmount] = useState(30);

  const handleSliderChange = (e, newValue) => {
    setAmount(newValue);
  };

  const handleDecrease = () => {
    if (amount > 0) {
      setAmount((prev) => prev - 1);
    }
  };
  const handleIncrease = () => {
    setAmount((prev) => prev + 1);
  };

  useEffect(() => {
    setValue("agent_tip", amount);
  }, [amount]);

  const image = useWatch({
    control,
    name: "image",
  });

  return (
    <Box className={styles.container} id="insurance">
      <Box className={styles.contentWrapper}>
        <Box className={styles.leftBox}>
          <Box className={styles.switchesRow}>
            <Box className={styles.switchLabel}>
              <Typography variant="h4" className={styles.switch_title}>
                Travel Protection
              </Typography>
              <IOSSwitch
                disabled
                checked={travelProtection}
                onChange={(e) => setTravelProtection(e.target.checked)}
              />
            </Box>
          </Box>
          <Box className={styles.featuresCard}>
            {protectionFeatures.map((item) => (
              <Box key={item.id} className={styles.featureItem}>
                {item.icon}
                <Typography variant="h6" className={styles.text}>
                  {item.text}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box className={styles.rightBox}>
          <Box className={styles.switchLabel}>
            <Typography variant="h4" className={styles.title}>
              Want to express your gratitude to your agent?
            </Typography>
          </Box>
          <Box className={styles.gratitudeCard}>
            <Box className={styles.header}>
              <Box className={styles.avatar}>
                <Avatar
                  src={image || "/ava.png"}
                  alt="Agent"
                  className={styles.ava}
                />
                <span />
              </Box>
              <Typography variant="subtitle1" className={styles.title}>
                Rate the agent's service
              </Typography>
            </Box>

            <Box className={styles.amountWrapper}>
              <Box className={styles.amount}>
                <Box className={styles.icon} onClick={handleDecrease}>
                  <RemoveIcon />
                </Box>

                {/* <TextField
                  type="number"
                  value={amount}
                  size="small"
                  onChange={(e) => setAmount(Number(e.target.value))}
                  inputProps={{ min: 1, style: { textAlign: "center" } }}
                  className={styles.amountInput}
                /> */}
                <Typography variant="p" className={styles.amount_text}>
                  ${amount}
                </Typography>

                <Box className={styles.icon} onClick={handleIncrease}>
                  <AddIcon />
                </Box>
              </Box>
              <Typography variant="body2" className={styles.note}>
                You express your gratitude with the following amount of money
              </Typography>
            </Box>

            <Box className={styles.sliderWrapper}>
              <Slider
                value={amount}
                onChange={handleSliderChange}
                aria-label="Gratitude Amount"
                marks={marks}
                min={0}
                max={100}
                sx={{
                  width: "423px",
                  margin: "0 auto 16px",
                }}
                size="big"
              />
              <Box className={styles.agree}>
                <Checkbox
                  checked={confirmTip}
                  onChange={() => setConfirmTip((prev) => !prev)}
                />
                <Typography className={styles.agree_title}>
                  <span>I agree,</span> that this amount will be charged in
                  addition to the cost of the airline ticket(s)
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InsuranceSection;
