import { Box, Modal, Typography } from "@mui/material";
import ImageGallery from "react-image-gallery";
import styles from "./styles.module.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import CloseIcon from "@mui/icons-material/Close";
import useWindowWidth from "../../../hooks/useWindow";

const HotelGalleryPopup = ({ isOpen, close, index, images }) => {
  const width = useWindowWidth();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    maxHeight: "90vh",
    px: 4,
    pb: 3,
    borderRadius: "10px",
    maxWidth: width > 992 ? "920px" : "90%",
  };

  const imgs = images?.map((item) => {
    return {
      original: item,
      thumbnail: item,
    };
  });

  return (
    <Modal
      open={isOpen}
      onClose={close}
      className={styles.order_popup}
      id="hotel-gallery-pop"
    >
      <Box className={styles.card} style={modalStyle}>
        <Box className={styles.header}>
          <Typography variant="h5" className={styles.title}>
            Gallery
          </Typography>
          <CloseIcon onClick={close} />
        </Box>
        {images && (
          <ImageGallery
            items={imgs}
            startIndex={index || 0}
            showThumbnails={true}
            showPlayButton={false}
            showFullscreenButton={false}
            useBrowserFullscreen
            additionalClass={styles.customGallery}
          />
        )}
      </Box>
    </Modal>
  );
};

export default HotelGalleryPopup;
