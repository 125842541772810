import { Box, TextField, Typography } from "@mui/material";
import Card from "../Card";
import styles from "./style.module.scss";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Controller } from "react-hook-form";

const ContactInformation = ({ phone_number, email, control }) => {
  return (
    <Box className={styles.section}>
      <Typography className={styles.title}>Contact Information</Typography>
      <div className={styles.wrapper}>
        <div className={styles.contact}>
          <Typography className={styles.title}>Phone Number*</Typography>
          <Controller
            name="contact_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                value={phone_number}
                placeholder="Enter phone number"
                type="number"
                InputProps={{
                  required: true,
                }}
                required={true}
              />
            )}
          />
        </div>
        <div className={styles.contact}>
          <Typography className={styles.title}>Email*</Typography>
          <Controller
            name="contact_email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="email"
                placeholder="Enter contact email"
                value={email}
                InputProps={{
                  required: true,
                }}
                required={true}
              />
            )}
          />
        </div>
      </div>
    </Box>
  );
};

export default ContactInformation;
