import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog } from "@mui/material";
import React from "react";
import styles from "./style.module.scss";
import MobileTicketVariant from "../MobileTicket";

export default function TicketModal({
  open,
  onClose,
  key,
  onlyRead,
  data,
  isRoundTrip,
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
          width: "-webkit-fill-available",
        },
      }}
    >
      <Box className={styles.box}>
        <div className={styles.header}>
          <div className={styles.content}>
            <h3>Flights Information</h3>
          </div>
          <CloseIcon onClick={handleClose} className={styles.close} />
        </div>
        <Box className={styles.body}>
          <MobileTicketVariant
            key={key}
            onlyRead
            data={data}
            isRoundTrip={isRoundTrip}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
