import { Box, Typography } from "@mui/material";
import styles from "./style.module.scss";
import NewTicketVariant from "../../../components/NewTicketVariant";
import useWindowWidth from "../../../hooks/useWindow";
import OneWayTicketVariant from "../../../components/OneWayTicketVariant";

export default function FlightInfo({ flights, user }) {
  const windowWidth = useWindowWidth();
  return (
    <>
      <Box className={styles.section}>
        <Typography variant="h5" className={styles.title}>
          Flights Information
        </Typography>
        <Box width="100%" className={styles.grid}>
          {flights?.map((item) => (
            <>
              {windowWidth > 992 ? (
                <NewTicketVariant
                  key={item.id}
                  onlyRead
                  data={JSON.parse(item.info)}
                  isRoundTrip={item.type === "round_trip"}
                />
              ) : (
                <OneWayTicketVariant
                  key={item.id}
                  onlyRead
                  data={JSON.parse(item.info)}
                  isRoundTrip={item.type === "round_trip"}
                />
              )}
            </>
          ))}
        </Box>
      </Box>
    </>
  );
}
