import { Box, CircularProgress, Container } from "@mui/material";
import Header from "./Header";
import FlightInfo from "./FlightInfo";
import styles from "./style.module.scss";
import useOrderAPI from "../../hooks/api/useOrderAPI";
import Customers from "./Customers";
import Insurance from "./Insurance";
import Transport from "./Transport";
import Tip from "./Tip";
import PaymentInfo from "./PaymentInfo";
import { useForm } from "react-hook-form";
import ConfirmModal from "./ConfirmModal";
import SuccessModal from "./SuccessModal";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContactInformation from "./ContactInformation";
import OrderConfirmed from "./OrderConfirmed";
import HotelInfo from "./HotelInfo";
import TransportSection from "./TransportSection";
import Sponsors from "./Sponsors";
import InsuranceSection from "./InsuranceSection";
import useWindowWidth from "../../hooks/useWindow";

const OrderPreview = () => {
  const { id } = useParams();
  const windowWidth = useWindowWidth();
  const { order, confirmOrder, isLoadingCurrentOrder, refetchCurrentOrder } =
    useOrderAPI({
      orderId: id,
    });
  const [success, setSuccess] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmTip, setConfirmTip] = useState(false);
  const [comments, setComments] = useState([]);

  const { setValue, control, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      with_hotel: order?.with_hotel,
      with_transport: order?.with_transport,
      has_insurance: true,
      agent_tip: 30,
      is_rent_car: false,
      is_airport_transfer: false,
      orderHotels: order?.orderHotels,
      contact_number: null,
      contact_email: null,
      insurance_price: 0,
      image: order?.user?.image,
    },
  });

  useEffect(() => {
    if (!order) return;

    if (order?.is_confirmed) {
      setSuccess(true);
    }

    reset({
      with_hotel: order.with_hotel,
      with_transport: order.with_transport,
      has_insurance: order.has_insurance,
      orderHotels: order?.orderHotels,
      agent_tip: 30,
      image: order?.user?.image,
      contact_number: order?.contact_number,
      contact_email: order?.contact_email,
    });

    setComments(
      order.customers.map((customer) => ({
        customer_id: customer.id,
        comment: null,
      }))
    );
  }, [order]);

  // useEffect(() => {
  //   if (!order) return;

  //   setValue(
  //     "insurance_price",
  //     watch().has_insurance ? Number(order.payment.price * 0.09) : 0
  //   );
  // }, [watch().has_insurance, order]);

  const onChangeComment = (e, idx) => {
    const _comments = [...comments];

    _comments[idx].comment = e.target.value;

    setComments(_comments);
  };

  const onSubmit = (data) => {
    setConfirm(true);
  };

  const handleConfirm = useCallback(() => {
    confirmOrder.mutate(
      {
        ...watch(),
        is_confirmed: true,
        id,
        customers_comments: comments,
      },
      {
        onSuccess: () => {
          setConfirm(false);
          setSuccess(true);
          refetchCurrentOrder();
        },
      }
    );
  }, [watch, reset, comments]);

  if (isLoadingCurrentOrder)
    return (
      <Box
        width="100%"
        display="flex"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={60} />
      </Box>
    );

  if (order?.is_confirmed) return <OrderConfirmed />;

  return (
    <>
      <Header
        externalOrderId={order?.external_order_id}
        control={control}
        agent_number={order?.user?.phone_number}
        agent_fullname={[order?.user?.first_name, order?.user?.last_name].join(
          " "
        )}
      />
      <main className={styles.main}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container className={styles.container}>
            <div className={styles.box}>
              <HotelInfo control={control} />
              <FlightInfo user={order?.user} flights={order?.flights} />
              <Customers
                customers={order?.customers}
                comments={comments}
                onChangeComment={onChangeComment}
                customerPayments={order?.customer_payments}
              />
              <TransportSection
                watch={watch}
                setValue={setValue}
                control={control}
              />

              <ContactInformation
                phone_number={order?.contact_number}
                email={order?.contact_email}
                control={control}
              />
              <InsuranceSection
                setValue={setValue}
                confirmTip={confirmTip}
                setConfirmTip={setConfirmTip}
                control={control}
              />
              {/* <Insurance control={control} /> */}
              {/* <Transport control={control} /> */}
              {/* <Tip
                setValue={setValue}
                confirmTip={confirmTip}
                setConfirmTip={setConfirmTip}
              /> */}
              {windowWidth > 992 && <Sponsors />}
            </div>
            <div className={styles.payment}>
              <PaymentInfo
                isConfirmed={order?.is_confirmed}
                confirmTip={confirmTip}
                control={control}
                payment={order?.payment}
                insurance_price={watch().insurance_price}
                customerPayments={
                  order?.customer_payments
                    ? JSON.parse(order?.customer_payments)
                    : []
                }
                passengerPaymentInfo={order.passengers_payment_info}
                externalOrderId={order?.external_order_id}
              />
            </div>
            {windowWidth < 992 && <Sponsors />}
          </Container>
        </form>
      </main>
      <ConfirmModal
        open={confirm}
        setOpen={setConfirm}
        handleConfirm={handleConfirm}
        isLoading={confirmOrder.isLoading}
      />
      <SuccessModal
        externalOrderId={order?.external_order_id}
        open={success}
        setOpen={setSuccess}
      />
    </>
  );
};

export default OrderPreview;
