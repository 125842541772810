export const _flights = [
  {
    type: "flight-offer",
    id: "1",
    source: "GDS",
    instantTicketingRequired: false,
    nonHomogeneous: false,
    oneWay: false,
    lastTicketingDate: "2023-11-15",
    lastTicketingDateTime: "2023-11-15",
    numberOfBookableSeats: 1,
    itineraries: [
      {
        duration: "PT25H30M",
        segments: [
          {
            departure: {
              iataCode: "TAS",
              terminal: "2",
              at: "2023-11-15T01:10:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2023-11-15T04:30:00",
            },
            carrierCode: "HY",
            number: "281",
            aircraft: {
              code: "332",
            },
            operating: {
              carrierCode: "HY",
            },
            duration: "PT5H20M",
            id: "39",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2023-11-15T07:25:00",
            },
            arrival: {
              iataCode: "CDG",
              terminal: "2E",
              at: "2023-11-15T09:10:00",
            },
            carrierCode: "AF",
            number: "1391",
            aircraft: {
              code: "320",
            },
            operating: {
              carrierCode: "AF",
            },
            duration: "PT3H45M",
            id: "40",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "CDG",
              terminal: "2E",
              at: "2023-11-15T14:00:00",
            },
            arrival: {
              iataCode: "IAD",
              at: "2023-11-15T16:40:00",
            },
            carrierCode: "AF",
            number: "54",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "AF",
            },
            duration: "PT8H40M",
            id: "41",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
      {
        duration: "PT23H25M",
        segments: [
          {
            departure: {
              iataCode: "IAD",
              at: "2023-11-15T18:45:00",
            },
            arrival: {
              iataCode: "CDG",
              terminal: "2E",
              at: "2023-11-16T08:15:00",
            },
            carrierCode: "AF",
            number: "55",
            aircraft: {
              code: "77W",
            },
            operating: {
              carrierCode: "AF",
            },
            duration: "PT7H30M",
            id: "82",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "CDG",
              terminal: "2E",
              at: "2023-11-16T11:45:00",
            },
            arrival: {
              iataCode: "IST",
              at: "2023-11-16T17:05:00",
            },
            carrierCode: "AF",
            number: "1390",
            aircraft: {
              code: "32A",
            },
            operating: {
              carrierCode: "AF",
            },
            duration: "PT3H20M",
            id: "83",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
          {
            departure: {
              iataCode: "IST",
              at: "2023-11-16T21:40:00",
            },
            arrival: {
              iataCode: "TAS",
              terminal: "2",
              at: "2023-11-17T04:10:00",
            },
            carrierCode: "HY",
            number: "274",
            aircraft: {
              code: "788",
            },
            operating: {
              carrierCode: "HY",
            },
            duration: "PT4H30M",
            id: "84",
            numberOfStops: 0,
            blacklistedInEU: false,
          },
        ],
      },
    ],
    price: {
      currency: "USD",
      total: "1751.75",
      base: "1135.00",
      fees: [
        {
          amount: "0.00",
          type: "SUPPLIER",
        },
        {
          amount: "0.00",
          type: "TICKETING",
        },
      ],
      grandTotal: "1751.75",
    },
    pricingOptions: {
      fareType: ["PUBLISHED"],
      includedCheckedBagsOnly: true,
    },
    validatingAirlineCodes: ["AF"],
    travelerPricings: [
      {
        travelerId: "1",
        fareOption: "STANDARD",
        travelerType: "ADULT",
        price: {
          currency: "USD",
          total: "1751.75",
          base: "1135.00",
        },
        fareDetailsBySegment: [
          {
            segmentId: "39",
            cabin: "ECONOMY",
            fareBasis: "QS50ABS0",
            class: "L",
            includedCheckedBags: {
              quantity: 1,
            },
          },
          {
            segmentId: "40",
            cabin: "ECONOMY",
            fareBasis: "QS50ABS0",
            class: "L",
            includedCheckedBags: {
              quantity: 1,
            },
          },
          {
            segmentId: "41",
            cabin: "ECONOMY",
            fareBasis: "QS50ABS0",
            class: "Q",
            includedCheckedBags: {
              quantity: 1,
            },
          },
          {
            segmentId: "82",
            cabin: "ECONOMY",
            fareBasis: "QS50ABS0",
            class: "Q",
            includedCheckedBags: {
              quantity: 1,
            },
          },
          {
            segmentId: "83",
            cabin: "ECONOMY",
            fareBasis: "QS50ABS0",
            class: "L",
            includedCheckedBags: {
              quantity: 1,
            },
          },
          {
            segmentId: "84",
            cabin: "ECONOMY",
            fareBasis: "QS50ABS0",
            class: "L",
            includedCheckedBags: {
              quantity: 1,
            },
          },
        ],
      },
    ],
  },
];

export const flightTypes = {
  ONE_WAY: "one_way",
  ROUND_TRIP: "round_trip",
  DIFFICULT_ROUTE: "difficult_route",
};

export const flightClasses = {
  BUSINESS: "business",
  ECONOM: "econom",
};

export const flightClassesArray = [
  {
    key: flightClasses.BUSINESS,
    value: "Бизнес клас",
  },
  {
    key: flightClasses.ECONOM,
    value: "Эконом клас",
  },
];

export const flightTypesArray = [
  {
    key: flightTypes.ONE_WAY,
    value: "One Way",
  },
  {
    key: flightTypes.ROUND_TRIP,
    value: "Round trip",
  },
  {
    key: flightTypes.DIFFICULT_ROUTE,
    value: "Multiple flights",
  },
];

export const iataCodes = [
  {
    key: "CVG",
    value: "Cincinatti - CVG",
  },
  {
    key: "CLE",
    value: "Cleveland - CLE",
  },
  {
    key: "CMH",
    value: "Columbus - CMH",
  },
  {
    key: "RSW",
    value: "Ford Mayers - RSW",
  },
  {
    key: "MCO",
    value: "Orlando - MCO",
  },
  {
    key: "ATL",
    value: "Atalanta - ATL",
  },
  {
    key: "IAH",
    value: "Houston - IAH",
  },

  {
    key: "DFW",
    value: "Dallas - DFW",
  },
  {
    key: "BOS",
    value: "Boston - BOS",
  },

  {
    key: "BWI",
    value: "Baltimore - BWI",
  },
  {
    key: "ORD",
    value: "Chicago - ORD",
  },

  {
    key: "TAS",
    value: "Tashkent - TAS",
  },
  {
    key: "IST",
    value: "Istanbul - IST",
  },
  {
    key: "IAD",
    value: "Washington DC - IAD",
  },
  {
    key: "NYC",
    value: "New York - NYC",
  },
  {
    key: "DYU",
    value: "Dyushanbe - DYU",
  },
  {
    key: "SKD",
    value: "Samarkand - SKD",
  },
  {
    key: "BHK",
    value: "Bukhara - BHK",
  },
  {
    key: "UGC",
    value: "Urgench - UGC",
  },
  {
    key: "NMA",
    value: "Namangan - NMA",
  },
  {
    key: "FEG",
    value: "Ferghana - FEG",
  },
  {
    key: "AZN",
    value: "Andijan - AZN",
  },
];

export const isurancePercentage = 9;

export const paymentTypes = {
  PENDING: "pending",
  PAID: "paid",
};

export const paymentTypesArray = [
  {
    key: paymentTypes.PENDING,
    value: "Pending",
  },
  {
    key: paymentTypes.PAID,
    value: "Paid",
  },
];

export const carrierCodes = [
  {
    key: "HY",
    value: "HY",
  },
  {
    key: "TK",
    value: "TK",
  },
  {
    key: "UA",
    value: "UA",
  },
  {
    key: "OZ",
    value: "OZ",
  },
  {
    key: "EK",
    value: "EK",
  },
  {
    key: "B9",
    value: "B9",
  },
  {
    key: "LO",
    value: "LO",
  },
];

export const airlineLogos = [
  "/airlines/AA.png",
  "/airlines/DL.png",
  "/airlines/EK.png",
  "/airlines/F9.png",
  "/airlines/UA.png",
  "/airlines/HY.png",
  "/airlines/LO.png",
  "/airlines/MS.png",
  "/airlines/NK.png",
  "/airlines/TK.png",
];
