import { Box, Button, Rating } from "@mui/material";
import DomainIcon from "@mui/icons-material/Domain";
import styles from "./style.module.scss";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Breakfast from "../../../assets/icons/breakfast.svg";
import { useWatch } from "react-hook-form";
import RoomImages from "../RoomImages";
import moment from "moment";
import classNames from "classnames";
import { useState } from "react";
import PoliciesModal from "../PiliciesModal";

export default function HotelInfo({ control }) {
  const [fixedHeight, setFixedHeight] = useState(true);
  const [openModalId, setOpenModalId] = useState(null);

  const handleOpenModal = (hotelId) => {
    setOpenModalId(hotelId);
  };

  const handleCloseModal = () => {
    setOpenModalId(null);
  };

  const hotels = useWatch({
    control,
    name: "orderHotels",
  });

  const getRooms = (hotel) => {
    if (!hotel) return [];

    const rooms = hotel?.hotelRooms || [];
    const roomCount = rooms.reduce((acc, room) => {
      const { roomType } = room || {};
      const name = roomType?.name;
      if (!name) return acc;
      acc[name] = (acc[name] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(roomCount).map(([name, count]) => (
      <p key={name}>
        {name} - {count}
      </p>
    ));
  };

  const getAllRoomOptions = (rooms) => {
    if (!Array.isArray(rooms)) return [];

    const uniqueOptions = new Map();

    return rooms
      .flatMap((room) => room?.roomOptions || [])
      .filter((option) => {
        const key = JSON.stringify(option);
        if (uniqueOptions.has(key)) {
          return false;
        }
        uniqueOptions.set(key, option);
        return true;
      });
  };

  const createHotelData = (item) => {
    return [
      {
        key: "Date",
        value: `${moment(item?.check_in).format("YYYY-MM-DD")} - ${moment(
          item?.check_out
        ).format("YYYY-MM-DD")}`,
      },
      {
        key: "Check-in / Check-out",
        value: `${moment.utc(item?.check_in).format("HH:mm")} - ${moment
          .utc(item?.check_out)
          .format("HH:mm")}`,
      },
      { key: "Room Type", value: getRooms(item) },
      {
        key: "Number of Guests",
        value: `${item?.adult > 0 ? `Adults: ${item?.adult}` : ""} ${
          item?.children > 0 ? `Children: ${item?.children}` : ""
        } ${item?.infant > 0 ? `Infants: ${item?.infant}` : ""}`,
      },
      { key: "Minimum check-in age", value: item?.max_age },
      {
        key: "Nights",
        value: calculateNights(item?.check_in, item?.check_out),
      },
    ];
  };

  const calculateNights = (checkIn, checkOut) => {
    const checkInDate = moment(checkIn).startOf("day");
    const checkOutDate = moment(checkOut).startOf("day");

    return checkOutDate.diff(checkInDate, "days");
  };

  return (
    <>
      {hotels?.map((item) => {
        const hotelData = createHotelData(item);
        const roomOptions = getAllRoomOptions(item?.hotelRooms);
        return (
          <>
            <Box className={styles.section}>
              <h4 className={styles.section_title}>Hotel Information</h4>

              <Box className={styles.grid}>
                <Box className={`${styles.card} ${styles.info}`}>
                  <Box className={styles.hotel_header}>
                    <Box className={styles.iconWrapper}>
                      <DomainIcon size={24} color="#344054" />
                    </Box>
                    <Box className={styles.hotel_desc}>
                      <p className={styles.hotel_key}>Hotel</p>
                      <h5 className={styles.value}>{item?.hotel?.name}</h5>
                      <Rating
                        name="rating"
                        value={item?.hotel?.category}
                        readOnly
                        className={styles.rating}
                      />
                    </Box>
                  </Box>

                  <Box className={styles.hotel_body}>
                    <p className={styles.key}>Address</p>
                    <h5 className={styles.value}>
                      {`${item?.hotel?.address}, ${item?.hotel?.region?.name}, ${item?.hotel?.region?.country?.name}`}
                    </h5>
                  </Box>

                  <Box className={styles.hotel_footer}>
                    <Box className={styles.data_card}>
                      {hotelData.map((item, idx) => (
                        <Box key={idx} className={styles.data_item}>
                          <p className={styles.data_key}>{item.key}</p>
                          <h5 className={styles.data_value}>{item.value}</h5>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>

                <Box className={styles.sidebar}>
                  <Box className={styles.rating}>
                    <Box className={styles.iconWrapper}>
                      <StarBorderIcon size={24} color="#344054" />
                    </Box>
                    <Box className={styles.rating_desc}>
                      <p className={styles.key}>Rating</p>
                      <Rating
                        name="rating"
                        value={item?.hotel?.category}
                        readOnly
                      />
                    </Box>
                  </Box>

                  <Box className={styles.options}>
                    <p className={styles.key}>Additional Room Options</p>
                    <ul>
                      {roomOptions.map((item) => (
                        <li>
                          <img
                            alt="icon"
                            src={item?.icon || "/images/default.jpg"}
                          />
                          {item?.name}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>

                <Box className={`${styles.card} ${styles.policies}`}>
                  <p className={styles.key}>Policies</p>
                  <p
                    className={classNames(styles.value, {
                      [styles.fixed_desc]:
                        fixedHeight && item?.policies?.length > 50,
                    })}
                    dangerouslySetInnerHTML={{
                      __html: item?.policies?.replace(/\n/g, "<br>"),
                    }}
                  />

                  {item?.policies?.length > 50 && (
                    <a
                      href="#read-more"
                      className={styles.more}
                      // onClick={() => setFixedHeight((prev) => !prev)}
                      onClick={() => handleOpenModal(item?.id)}
                    >
                      {fixedHeight ? "Read more" : "Read less"}
                    </a>
                  )}
                </Box>
              </Box>
            </Box>
            <RoomImages images={item?.hotel?.images} />
            <PoliciesModal
              onClose={handleCloseModal}
              open={openModalId === item?.id}
              control={control}
              policies={item?.policies}
            />
          </>
        );
      })}
    </>
  );
}
