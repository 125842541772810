import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./style.module.scss";
import HotelGalleryPopup from "../GalleryModal";
import useWindowWidth from "../../../hooks/useWindow";

const RoomImages = ({ images }) => {
  const width = useWindowWidth();
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(false);
  const MAX_VISIBLE = width > 992 ? 5 : width < 566 ? 4 : 3;
  const visibleImages = images?.slice(0, MAX_VISIBLE);
  const restCount =
    images?.length - MAX_VISIBLE > 0 ? images?.length - MAX_VISIBLE : 0;

  return (
    <Box className={styles.container}>
      <Typography variant="h6" className={styles.title}>
        Room Image
      </Typography>

      <Box className={styles.images}>
        {visibleImages.map((img, index) => {
          const isLastVisibleImage = index === MAX_VISIBLE - 1 && restCount > 0;
          return (
            <Box key={img.id} className={styles.imageContainer}>
              <img
                src={img}
                alt={`Preview ${img.id}`}
                className={styles.image}
                onClick={() => {
                  setOpen(true);
                  setIndex(index);
                }}
              />

              {isLastVisibleImage && (
                <Box className={styles.overlay} onClick={() => setOpen(true)}>
                  <Typography
                    variant="h5"
                    component="span"
                    className={styles.overlayText}
                  >
                    +{restCount}
                  </Typography>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>

      <HotelGalleryPopup
        images={images}
        isOpen={open}
        index={index}
        close={() => setOpen(false)}
      />
    </Box>
  );
};

export default RoomImages;
