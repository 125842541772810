import Card from "../Card";
import styles from "./style.module.scss";
import classNames from "classnames";
import { useWatch } from "react-hook-form";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import pdf from "../Terms.pdf";
import getHotelsTotalSum from "../../../hooks/useGetHotelsPirce";

export default function PaymentInfo({
  payment,
  control,
  isConfirmed,
  confirmTip,
  insurance_price,
  externalOrderId,
  customerPayments,
  passengerPaymentInfo,
}) {
  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [openHotelPrice, setOpenHotelPrice] = useState(false);
  const tipPrice = useWatch({
    control,
    name: "agent_tip",
  });
  const hasInsurance = useWatch({
    control,
    name: "has_insurance",
  });

  const hotels = useWatch({
    control,
    name: "orderHotels",
  });
  console.log("passengerPaymentInfo", passengerPaymentInfo);
  const hotelSum = getHotelsTotalSum(hotels, "grous_price");
  return (
    <Box className={styles.box}>
      <Typography className={styles.title} variant="h4">
        Payment information
      </Typography>
      <Box className={styles.card}>
        <div className={styles.info}>
          <div className={styles.element}>
            <p>Transaction ID</p>
            <p>{externalOrderId}</p>
          </div>

          <div className={styles.element}>
            <p>Payment status</p>
            <p
              className={classNames(styles.status, {
                [styles.success]: payment?.status === "success",
              })}
            >
              {payment?.status === "pending" ? "Pending" : "Success"}
            </p>
          </div>
        </div>
        <div className={styles.check}>
          {Number(hotelSum) > 0 && (
            <>
              <h4 className={styles.check_title}>Check</h4>
              <div
                onClick={() => setOpenHotelPrice((prev) => !prev)}
                className={classNames(styles.value, styles.accordion)}
              >
                <p className={styles.key}>Hotel price</p>
                <p className={styles.value_text}>
                  ${hotelSum}
                  <KeyboardArrowDownRoundedIcon
                    style={{
                      transform: `rotate(${!openHotelPrice ? 0 : 180}deg)`,
                    }}
                  />
                </p>
              </div>
            </>
          )}

          {openHotelPrice &&
            hotels?.map((data) => (
              <div className={styles.accordionInner}>
                <p className={styles.key}>{data?.hotel?.name}</p>
                <p className={styles.value_text}>${data?.grous_price}</p>
              </div>
            ))}
          {Number(payment?.price) > 0 && (
            <div
              onClick={() => setOpen((prev) => !prev)}
              className={classNames(styles.value, styles.accordion)}
            >
              <p className={styles.key}>Flight ticket</p>
              <p className={styles.value_text}>
                ${payment?.price}
                <KeyboardArrowDownRoundedIcon
                  style={{
                    transform: `rotate(${!open ? 0 : 180}deg)`,
                  }}
                />
              </p>
            </div>
          )}
          {open &&
            passengerPaymentInfo?.map((data) => (
              <div className={styles.accordionInner}>
                <p className={styles.key}>{data.fullname}</p>
                <p className={styles.value}>${data?.ticket_price_gross}</p>
              </div>
            ))}

          {hasInsurance && (
            <div className={styles.value}>
              <p className={styles.key}>Travel Protection</p>
              <p className={styles.value_text}>Included</p>
            </div>
          )}
          {Number(payment?.package_price_gross) > 0 && (
            <div className={styles.value}>
              <p className={styles.key}>Package price</p>
              <p className={styles.value_text}>
                {payment?.package_price_gross}
              </p>
            </div>
          )}
          <div className={styles.value}>
            <p className={styles.key}>Tips</p>
            <p className={styles.value_text}>${tipPrice || 0}</p>
          </div>
          <div className={styles.total}>
            <p className={styles.total_text}>Total </p>
            <p className={styles.value_text}>
              ${payment?.total + (tipPrice || 0)}
            </p>
          </div>
          <p className={styles.conditions}>
            <Checkbox
              checked={isReady}
              onChange={(e) => setIsReady(e.target.checked)}
            />{" "}
            <span>
              {" "}
              By clicking the confirm application button, I agree to our{" "}
              <a href={pdf} target="_blank" rel="noreferrer">
                terms and conditions
              </a>{" "}
              and policies of hotel.
            </span>
          </p>
          <button disabled={!isReady || !confirmTip} type="submit">
            Confirm application
          </button>
        </div>
      </Box>
    </Box>
  );
}
